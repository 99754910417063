<template>
  <div v-if="question">
    <sqr-input-number
      :label="question.name"
      :value="answer"
      :min="question.min"
      :max="question.max"
      :required="question.required"
      @change="change"
      :disabled="disabled"
      :v="v"
    />
  </div>
</template>

<script>
import SqrInputNumber from '@/sqrd-ui/SqrInputNumber';
export default {
  name: 'ProductView',
  components: { SqrInputNumber },
  props: {
    question: { type: Object },
    answer: { type: Number },
    disabled: { type: Boolean },
    v: { type: Object },
  },
  methods: {
    change(value) {
      this.$emit('change', parseInt(value));
    },
  },
};
</script>
